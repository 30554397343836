$(() => {
  $('select.question').on('change', gradeOne)
  $('input#grade-2').on('keyup', finalGrade)
  $('input#is-draft').on('change', showCorrectButton)
  $('table.table-row-click tr').on('click', rowSelect)
  $('select.table-row-click').on('change', selectChange)
})

const rowSelect = (e) => {
  let row = $(e.target);
  let tableID = row.parents('table').attr('id')
  $('table.table-row-click#' + tableID + ' *').removeClass('success')

  let dataInputID = row.data('colum-id');
  $('table.table-row-click#' + tableID + ' *[data-colum-id=' + dataInputID + ']').addClass('success');

  $('#answers-' + tableID + '-response').val(row.data('input-value'))
}

const selectChange = (e) => {
  let select = $(e.target);
  let id = select.prop('id');
  let index = select.prop('selectedIndex') - 1;
  let i = id.replace('answers-', '').replace('-response', '')
  $('[data-input-id=' + id + ']').removeClass('success')
  $('[data-colum-id=c-' + i + '-' + index + ']').addClass('success');
  let description = select.val() ? $('td[data-colum-id=c-' + i + '-' + index + ']').html() : 'Seleccione una opción'
  select.parent().find('p.help-block').html(description)
}

const showCorrectButton = () => {
  let isChecked = $('input#is-draft').is(':checked')
  $('button#draft')[isChecked ? 'show' : 'hide']();
  $('button#no-draft')[!isChecked ? 'show' : 'hide']();
}

const gradeOne = (e) => {
  let questions = $('select.question').length
  const grades = [];
  $('select.question').map(function(i, e) {
    if (Number($(e).val()) != -1) {
      grades.push(Number($(e).val()) || 0)
    } else {
      questions--
    }
  })

  let grade = grades.reduce((a, b) => a + b, 0) / questions
  $('input#grade-1').val(Chile.clean(grade))
  finalGrade()
}

const finalGrade = () => {
  let gradeOne = Number($('input#grade-1').val())
  let gradeTwo = Number($('input#grade-2').val().replace(',', '.'))

  if ($('input#grade-2').val() != '') {
    gradeTwo = Chile.clean(gradeTwo)
    // $('input#grade-2').val(gradeTwo)
  }

  $('input#grade').val(Chile.round((gradeOne * 0.6) + (gradeTwo * 0.4)))
}

const Chile = {
  decimals: 1,
  max: 7,
  min: 1,
  round: (grade) => {
    let rounded = Number(Math.round(grade + 'e' + Chile.decimals) + 'e-' + Chile.decimals)
    return rounded
  },
  clean: (grade) => {
    if (grade > Chile.max) grade = Chile.max
    if (grade < Chile.min) grade = Chile.min

    return Chile.round(grade)
  }
}

