$(() => {
  $('#profile-select').on('change', e => {
    const role_id = $(e.target).val()
    if (role_id) {
      window.location = `/users/role/${role_id}`
    }
    return false
  })

  $('#program-select').on('change', e => {
    const program_id = $(e.target).val()
    if (program_id) {
      window.location = `/programs/join/${program_id}`
    }
    return false
  })

})
