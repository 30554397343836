import axios from 'axios'

$(() => {
  const url = '/rotations/procedures/ID.json'

  // Configuración del carrusel de rotaciones
  $('.carousel').slick({
    arrows: false,
    dots: true,
    infinite: false,
    // slidesToScroll: 1,
    slidesToShow: 3,
    variableWidth: true,
    slickGoTo: 3,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false
        }
      }
    ]
  })

  // Dejo como slide inicial la rotación actual (Navega al slide según el index)
  const slickToGo = Number($('.rotation.active').parents('.slick-slide').attr('data-slick-index'))
  $('.carousel').slick('slickGoTo', slickToGo)

  // On swipe event
  const onChangeRotation = (slick, currentSlide) => {
    if (isNaN(currentSlide)) return;

    axios.get(url.replace('ID', currentSlide)).then(response => {
      const rotation = response.data.rotation
      const proceduresList = rotation.procedures

      const stepsDone = done => {
        let steps = ''
        let i = 0
        while (i < done) {
          steps += '<div class="step done"></div>'
          i++
        }
        return steps
      }

      const steps = (goal, done) => {
        let steps = ''
        let i = 0
        while (i < goal - done) {
          steps += '<div class="step"></div>'
          i++
        }
        return steps
      }

      const list = proceduresList.map(item => (
        `<div class="procedures-item">
          <div class="bar">
            ${stepsDone(item.done)}
            ${steps(item.goal, item.done)}
          </div>
          <div class="steps-indicator">${item.done} de ${item.goal} PROCEDIMIENTOS</div>
          <h4>${item.name}</h4>
        </div>`
      ))

      $('.procedures-list').html(list)
      $('.rotation-name span').text(` ${rotation.name}`)
    })
  }

  // LLamo los procedures de la rotación activa
  const activeRotation = Number($('.rotation.active').attr('data-id'))
  if (window.location.pathname === '/dist/home' || window.location.pathname === '/' || window.location.pathname === '/rotations' || window.location.pathname === '/rotations/index') {
    onChangeRotation(null, activeRotation)
  }

  // Mediaquery para decidir con qué evento se cargarán los procedimientos de cada rotación
  const matchMedia = () => {
    const mq = window.matchMedia('(max-width: 768px)')

    if (mq.matches) {
      // On swipe event
      $('.carousel').on('swipe', (event, slick, currentSlide) => {
        const current = Number($('.slick-current .rotation').attr('data-id'))
        onChangeRotation(slick, current)
      })
    } else {
      $('.rotation').click(e => {
        const current = Number($(e.currentTarget).attr('data-id'))
        onChangeRotation(null, current)
      })
    }
  }

  matchMedia()
})
