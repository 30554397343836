import validateRut from './validateRut'

$(() => {
  $('[data-toggle="tooltip"]').tooltip({
    trigger: 'focus'
  })
})

const validatePatientsForm = () => {
  let document_number = $('#patient-document-number').val()
  let name = $('#patient-name').val()
  let lastname = $('#patient-first-surname').val()
  let date = $('#patient-performed').val()

  const rutValido = document_number.trim() === '' ? true : validateRut(document_number)
  let emptyForm = name === '' || lastname === '' || date === ''

  if (!rutValido) {
    return false
  } else {
    if (emptyForm) {
      $('.tab-procedures, #add_form .btn').attr('data-original-title', 'Nombre, Apellido Materno, Edad y fecha de procedimiento son datos obligatorios.')
      return false
    } else {
      $('.tab-procedures, #add_form .btn').attr('data-original-title', '')
      return true
    }
  }
}

export default validatePatientsForm
