$(() => {
  $('form').submit(function(){
    $('button[type=submit]', this).attr('disabled', 'disabled').text('Enviando...');
  });

  const openArea = (section, itemToShow) => {
    $(`.add ${section} .checkbox`).click(event => {
      const _self = $(event.currentTarget)
      const input = _self.find('input')

      setTimeout(() => {
        if (!input.is(':checked')) {
          _self.removeClass('opened').find(`${itemToShow}`).slideUp('fast')
        } else {
          _self.addClass('opened').find(`${itemToShow}`).slideDown('fast')
        }
      }, 10)
    })
  }

  /* Abrir o cerrar textareas de anestesias */
  openArea('.anesthesia_types', '.textarea')

  /* Abrir o cerrar módulos de procedures realizados */
  openArea('.performed_procedures', '.procedure_result')

  /* Validar que se seleccione al menos 1 anesthesia y 1 procedimiento */
  const validateProceduresForm = () => {
    // let anesthesia = $('.anesthesias')
    let procedures = $('.procedures')
    // let selectedAnesthesia = []
    let selectedProcedures = []

    // for (let i = 0; i < anesthesia.length; i++) {
    //   if (anesthesia[i].checked === true) {
    //     selectedAnesthesia.push(anesthesia[i].value)
    //   }
    // }

    for (let i = 0; i < procedures.length; i++) {
      if (procedures[i].checked === true) {
        selectedProcedures.push(procedures[i].value)
      }
    }

    if (selectedProcedures.length === 0) {
      $('#procedures .btn').attr('data-original-title', 'Debe seleccionar al menos 1 área de desempeño.')
      return false
    } else {
      $('#procedures .btn').attr('data-original-title', '')
      return true
    }
  }

  $('.checkbox input').change(validateProceduresForm)
  $('#procedures .btn').click(validateProceduresForm)
})
