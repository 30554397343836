$(() => {
  $('.user-header button').click(e => {
    $('.user-menu').addClass('opened')
  })

  $('.user-menu button').click(e => {
    const _self = $(e.target)
    _self.parent().removeClass('opened')
  })
})
