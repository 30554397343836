import axios from 'axios'

$(() => {
  if (window.location.pathname === '/dist/add' || window.location.pathname === '/expedients/add') {
    const url = '/patients/search.json'
    $('#patient-document-number').autocomplete({
      source: `${url}?where=document_number`,
      minLength: 3,
      select: (event, ui) => {
        $('#patient-id').val(ui.item.id)
        $('#patient-name').val(ui.item.name)
        $('#patient-first-surname').val(ui.item.first_surname)
        $('#patient-second-surname').val(ui.item.second_surname)
        $('#patient-age-id').val(ui.item.age_id)
      }
    })
    .autocomplete( "instance" )._renderItem = function( ul, item ) {
      return $("<li>")
        .append("<div>" + item.value + "<br>" + item.name + " " + item.first_surname + " " + item.second_surname + "</div>")
        .appendTo(ul)
    }

    $('#patient-name').autocomplete({
      source: `${url}?where=name`,
      minLength: 3,
      select: (event, ui) => {
        $('#patient-id').val(ui.item.id)
        $('#patient-name').val(ui.item.name)
        $('#patient-first-surname').val(ui.item.first_surname)
        $('#patient-second-surname').val(ui.item.second_surname)
        $('#patient-age-id').val(ui.item.age_id)
        $('#patient-document-number').val(ui.item.document_number)
      }
    })
    .autocomplete( "instance" )._renderItem = function( ul, item ) {
      return $("<li>")
        .append("<div>" + item.value + "<br>" + (item.document_number ? item.document_number + " - " : "") + item.name + " " + item.first_surname + " " + item.second_surname + "</div>")
        .appendTo(ul)
    }

    $('#patient-first-surname').autocomplete({
      source: `${url}?where=first_surname`,
      minLength: 3,
      select: (event, ui) => {
        $('#patient-id').val(ui.item.id)
        $('#patient-name').val(ui.item.name)
        $('#patient-first-surname').val(ui.item.first_surname)
        $('#patient-second-surname').val(ui.item.second_surname)
        $('#patient-age-id').val(ui.item.age_id)
        $('#patient-document-number').val(ui.item.document_number)
      }
    })
    .autocomplete( "instance" )._renderItem = function( ul, item ) {
      return $("<li>")
        .append("<div>" + item.value + "<br>" + (item.document_number ? item.document_number + " - " : "") + item.name + " " + item.first_surname + " " + item.second_surname + "</div>")
        .appendTo(ul)
    }

    $('#tutor-id').select2();
    $('.anesthesias-types').select2();
    $('#expedient-type-id').select2();
    $('#asa-id').select2();
  }
})
